import styled from "styled-components"
import theme from "../../config/theme"

const Heading = styled.h2`
  color: ${({ color }) =>
    theme.colors && ((color && theme.colors[color]) || theme.colors.blue1)};
  margin-top: 0;
`

export default Heading
