import React from 'react'
import styled from 'styled-components'
import Layout from "../components/constructs/layout"
import Heading from "../components/primitives/heading"
import P from "../components/primitives/p"
import {taxis} from "../content/content"
import Container from "../components/primitives/container"
import theme from "../config/theme"

const StyledContainer = styled(Container)`
  padding: ${theme.spacing.large};
`

const Taxis = () => (
    <Layout title="Phone numbers for all taxi drivers in Skopelos and Skiathos" description="A summary of all ofthe taxi numbers for the Greek islands of Skiathos and Skopelos, to help you get between your scheduled ferry or flight and the port easier.">
        <StyledContainer bgColor="grey1">
        <Heading>Skopelos Taxi Numbers</Heading>
        <div style={{marginLeft:"16px"}}>
        {taxis["skopelos"].map((taxi) => (
            <P>{taxi.name}: <a href={`tel:${taxi.number}`} >{taxi.number}</a></P>
        ))}
        </div>
        <Heading style={{marginTop:"16px"}}>Skiathos Taxi Numbers</Heading>
        <div style={{marginLeft:"16px"}}>
        {taxis["skiathos"].map((taxi) => (
            <P>{taxi.name}: <a href={`tel:${taxi.number}`} >{taxi.number}</a></P>
        ))}
        </div>
        </StyledContainer>
    </Layout>
)

export default Taxis