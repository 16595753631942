export const ports = {
  skiathos: {
    title: "Skiathos Port",
    information:
      "You will find our boat waiting at the end of the main port of Skiathos where the ferries leave from. Look out for the orange stripe around our boat, the orange roof, and the taxi light on top.",
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3050.9689651350204!2d23.49115975409401!3d39.162037285839595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMznCsDA5JzQ0LjEiTiAyM8KwMjknMzEuNiJF!5e0!3m2!1sen!2sgr!4v1565954375829!5m2!1sen!2sgr",
  },
  loutraki: {
    title: "Loutraki Port, Glossa",
    information:
      "You will find our boat waiting at the end of the port in Loutraki. Look out for the orange stripe around our boat, the orange roof, and the taxi light on top.",
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3093.4142168405615!2d23.61303087235173!3d39.16530315491009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMznCsDA5JzU1LjMiTiAyM8KwMzYnNDcuNyJF!5e0!3m2!1sen!2sgr!4v1565954284364!5m2!1sen!2sgr",
  },
  agnondas: {
    title: "Agnondas Port",
    information:
      "You will find our boat waiting at the small port in Agnondas. It's a small area, so it will be hard to miss. Look out for the orange stripe around our boat, the orange roof, and the taxi light on top.",
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1548.5180686396716!2d23.710949573104276!3d39.08287459683152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a6c1b3f2228937%3A0x4ba670ab6218385d!2zQWdu4bmTbnRhcw!5e0!3m2!1sen!2sgr!4v1565951899443!5m2!1sen!2sgr&",
  },
  adrina: {
    title: "Adrina Hotel",
    information:
      "You will find our boat waiting at the small port outside of the Adrina Hotel. Look out for the orange stripe around our boat, the orange roof, and the taxi light on top.",
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3095.729333357315!2d23.65350531520279!3d39.112631979538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMznCsDA2JzQ1LjUiTiAyM8KwMzknMjAuNSJF!5e0!3m2!1sen!2sgr!4v1565954592699!5m2!1sen!2sgr",
  },
  elios: {
    title: "Elios Port",
    information:
      "You will find our boat waiting at the main port of Elios. Look out for the orange stripe around our boat, the orange roof, and the taxi light on top.",
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3094.605049219128!2d23.641942315203554!3d39.13821797953421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMznCsDA4JzE3LjYiTiAyM8KwMzgnMzguOSJF!5e0!3m2!1sen!2sgr!4v1565954677529!5m2!1sen!2sgr",
  },
}

export const taxis = {
  skopelos: [
  {
    name: "Giannis",
    number: "+30 694 4734024",
  },
  {
    name: "Dimitris",
    number: "+30 697 8025718",
  },
  {
    name: "Panos & Rania",
    number: "+30 697 9777830",
  },
  {
    name: "Stamatis",
    number: "+30 698 8544272",
  },
],
skiathos: [
  {
    name: "Adonis",
    number: "+30 698 4384576"
  }
]}
